@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-LightItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-Light.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-Light.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-LightItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-LightItalic.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-LightItalic.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-LightItalic.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-Regular.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-Regular.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-Regular.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-RegularItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-RegularItalic.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-RegularItalic.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-RegularItalic.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-Medium.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-Medium.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-Medium.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-MediumItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-MediumItalic.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-MediumItalic.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-MediumItalic.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-SemiBold.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-SemiBold.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-SemiBold.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-SemiBold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-SemiBoldItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-SemiBoldItalic.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-SemiBoldItalic.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-SemiBoldItalic.woff")
      format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-Bold.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-Bold.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-Bold.woff2") format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarity City";
  src: url("../../fonts/clarity-city/woff2/ClarityCity-BoldItalic.woff2");
  src: url("../../fonts/clarity-city/eot/ClarityCity-BoldItalic.eot")
      format("embedded-opentype"),
    url("../../fonts/clarity-city/woff2/ClarityCity-BoldItalic.woff2")
      format("woff2"),
    url("../../fonts/clarity-city/woff/ClarityCity-BoldItalic.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

.tk-proxima-nova {
  font-family: "Clarity City", sans-serif;
}
